import React, {useEffect, useState} from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import {useRouter} from "next/router";
import pageData from "./index.json";
import FullBagService from "../services/fullBag";
import {PXGIconPhone} from "pxg-web-shared";
const Promos = dynamic(() => import("../components/promos/Promos"));
const ContinueModal = dynamic(() => import("../components/modals/ContinueModal"));
import styles from "./index.module.scss";

function Index(page) {
	const {locale} = useRouter();
	const {localeResources} = page;
	const pageContent = pageData[locale];
	const [showContinueModal, setShowContinueModal] = useState(false);

	useEffect(() => {
		FullBagService.getFullBag().then((result) => {
			if (result.fullBagGuid && result.promoCode) {
				setShowContinueModal(true);
			}
		});
	}, []);

	return (
		<React.Fragment>
			<Head>
				<title>{pageContent.pageTitle}</title>
				<meta name="description" content={pageContent.metaData.description} />
				<meta property="og:title" content={pageContent.content.pageTitle} key="title" />
			</Head>
			<div className={styles.LandingPage}>
				<div className={` ${styles.container} container`}>
					<div className="row">
						<div className={`${styles.rightCol} col-12 text-center`}>
							<h1 className={`${styles.mainContentHeading} mt-4 mb-2 line-height-1`}>
								<span
									dangerouslySetInnerHTML={{
										__html: pageContent.content.headline,
									}}></span>
							</h1>
							<div className="row py-4">
								<div className="col-md-8 offset-md-2">
									<p
										className={styles.pageIntro}
										dangerouslySetInnerHTML={{
											__html: pageContent.content.content,
										}}></p>
								</div>
							</div>
							<Promos {...page} />
							<div className="row my-5">
								<div className="col-12 text-center">{pageContent.content.disclaimer}</div>
							</div>
							<div className={styles.disclaimer}>
								<hr className={styles.hr} />
								<div>
									<p className={styles.disclaimerOne}>{pageContent.content.needHelp}</p>
									<p className={styles.disclaimerTwo}>
										{pageContent.content.contactPlayerSupport}
										<a
											href={"tel:" + localeResources.phoneNumber}
											className={styles.phone}
											aria-label={
												localeResources.callSupport + " " + localeResources.phoneNumber
											}>
											<PXGIconPhone aria-hidden="true" elementClass={styles.phoneIcon} />
											<span aria-hidden="true">
												{localeResources.help + " " + localeResources.phoneNumberText}
											</span>
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ContinueModal {...page} show={showContinueModal}></ContinueModal>
		</React.Fragment>
	);
}
export default Index;
